import React from 'react';

export const LinesHome = () => (
  <svg
    width="192"
    height="745"
    viewBox="0 0 192 745"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible' }}
  >
    <path
      d="M90 3L-73.5 162C46.8333 145 150.5 269 -7.5 469C-205 719 202.5 -19 188.5 172.5C177.3 325.7 -73.8333 677 -253 745.5"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);
