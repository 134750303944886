import styled from '@emotion/styled';
import { mq } from '../../../config/styled/media';

export const HeroTop = styled.div`
  position: relative;
  padding: 63px 0;
  ${mq.lt.md} {
    overflow: hidden;
    padding: 0;
  }
`;

export const HeroTopActions = styled.div`
  margin-top: 126px;
  display: flex;
  gap: 10px;
  > * {
    &:first-child {
      width: auto;
    }
    &:last-child {
      display: none;
      width: auto;
    }
  }
  ${mq.lt.md} {
    margin-top: 55px;
    > * {
      &:first-child {
        width: auto;
      }
      &:last-child {
        display: block;
      }
    }
  }
  ${mq.lt.md} {
    margin-top: 55px;
    > * {
      &:first-child {
        flex-grow: 1;
      }
    }
  }
`;

export const HeroTopLeft = styled.div`
  position: relative;
`;

export const MarketingBadgeHolder = styled.div`
  position: absolute;
  top: -60px;
  right: 9%;
  z-index: -1;
  width: 293px;
  ${mq.lt.xl} {
    width: 290px;
  }
  ${mq.lt.md} {
    width: 170px;
    top: -90px;
    transform: translateX(30%);
    right: 0;
  }
`;

export const HomeYearHolder = styled.div`
  position: absolute;
  left: -10px;
  top: 51px;
  ${mq.lt.md} {
    left: 0px;
    top: 211px;
  }
`;

export const HomeTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  width: 100%;
  ${mq.lt.md} {
    gap: 0px;
    flex-direction: column;
  }
`;

export const HomeTopLeft = styled.div`
  flex-basis: 69%;
  ${mq.lt.md} {
    order: 2;
    padding: 24px 32px;
  }
`;

export const HomeTopRight = styled.div`
  ${mq.lt.md} {
    order: 1;
    width: 100%;
  }
`;

export const HomeLinesHolder = styled.div`
  position: absolute;
  ${mq.lt.md} {
    display: none;
  }
`;

export const HeroImage = styled.div`
  position: relative;
  ${mq.lt.md} {
    img {
      max-height: 457px;
      width: 100%;
      overflow: hidden;
    }
  }
`;

export const Heading = styled.h1`
  font-size: 2rem;
  margin: 16px 0 126px 0;
`;

export const BodyShape = styled.div`
  position: absolute;
  top: 50%;
  left: 26%;
`;

export const Crown = styled.div`
  position: absolute;
  top: 47%;
  left: 8%;
`;

export const Arrows = styled.div`
  position: absolute;
  top: 62%;
  left: 40%;
`;

export const ABC = styled.div`
  position: absolute;
  top: -29px;
  left: 41px;
  ${mq.lt.md} {
    svg {
      width: 150px;
    }
  }
`;

export const Texts = styled.div`
  position: relative;
  margin: 63px 0 42px 0;
  display: flex;
  flex-direction: column;
  gap: 63px;
  ${mq.lt.md} {
    gap: 24px;
  }
`;
