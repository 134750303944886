import React from 'react';

export const HomeYearIcon = () => (
  <svg width="146" height="82" viewBox="0 0 146 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.8938 47.1303C17.003 33.9988 39.2136 28.0696 32.494 43.7689C27.5123 55.408 22.9051 63.4007 18.9081 68.7382M18.9081 68.7382C2.81509 90.2284 -3.38515 68.6759 15.6942 68.7776C16.8152 68.7836 17.8857 68.77 18.9081 68.7382ZM18.9081 68.7382C39.6069 68.0941 40.5951 59.9904 42.8002 55.9761M57.6718 38.4229C51.5612 54.453 41.7915 86.9652 69.8009 45.6708C97.8104 4.3764 58.9944 24.9876 49.8779 40.5113M89.5218 31.0078C89.4977 31.5578 98.0073 28.3101 103.051 21.9131M103.051 21.9131C104.747 19.7609 106.052 17.2522 106.507 14.4117C106.715 13.1139 105.198 16.5161 103.051 21.9131ZM103.051 21.9131C98.9876 32.1256 92.6683 49.4806 91.5104 55.6477M122.751 11.3518C123.434 18.9627 121.249 23.6132 114.268 29.069C125.196 25.7771 146.606 21.8068 136.384 34.522C126.162 47.2371 110.87 50.711 107.377 51.1793M115.761 14.8713C129.581 12.8892 138.269 5.96686 143.865 3"
      stroke="#FF8310"
      strokeWidth="6"
    />
  </svg>
);
