import React from 'react';

export const WaveHome = () => (
  <svg width="100%" height="100%" viewBox="0 0 1728 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M277.329 47.5551C221.078 83.0271 6.98786 30.5166 0 0H542.766H1076.71H1598.36H1728C1724.78 4.73318 1705.24 26.2022 1652.85 74.2127C1600.47 122.223 1594.7 44.742 1598.36 0C1568.62 4.73318 1496.05 20.8706 1443.67 47.5551C1378.19 80.9106 1368.96 9.91289 1222.18 76.6239C1104.75 129.993 1076.28 47.7783 1076.71 0C1022.07 17.102 891.261 54.8424 805.126 68.9883C697.457 86.6708 858.301 -27.1935 700.534 14.1995C574.319 47.3139 542.766 18.5308 542.766 0C525.627 17.9057 478.516 59.0487 427.187 80.3748C363.025 107.032 347.644 3.21499 277.329 47.5551Z"
      fill="#FBFBFB"
    />
  </svg>
);
