import React from 'react';

export const ABCIcon = () => (
  <svg width="227" height="275" viewBox="0 0 227 275" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 101C26.6766 110.068 18.0905 214.609 6.5 267.5C81.6935 237.399 121.275 223.36 210 200.5M14.5 91C104.183 63.6554 145.483 37.7513 221 7C208.204 90.0443 213.315 142.757 221 217M28 232.5C43.1115 182.727 50.0399 154.728 57.5 104.5C65.8982 145.956 69.0193 168.888 70.5 209M33.5 184L81 153M88.5 101C95.2654 140.474 96.8541 162.822 96.5 203C123.167 180.667 162.2 129 105 101M193.5 102.5C193.5 72.5 160.31 67.9999 151.5 97C142.148 127.785 175.017 125.009 189 132C199 137 203 184.5 147 174.5"
      stroke="#FF8310"
      strokeWidth="8"
    />
  </svg>
);
