import React from 'react';

export const BodyShapeIcon = () => (
  <svg width="38" height="195" viewBox="0 0 38 195" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99999 2C11.3333 3.83333 17.6 10 16 20C14 32.5 -2.00001 12.5 7.99999 31C16 45.8 23.3333 68.8333 26 78.5C28 82.3333 30.4 89.3 24 86.5C16 83 34.5 146 35 152.5C35.5 159 32 168.5 24 168.5C16 168.5 2.50002 163.5 3.00002 170.5C3.40002 176.1 4.83334 188.833 5.5 194.5"
      stroke="#FF8310"
      strokeWidth="4"
    />
  </svg>
);
