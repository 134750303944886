import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Button } from '../../button';
import { ArrowIcon } from '../../icons/ArrowIcon';
import { ArrowsIcon } from '../../icons/ArrowsIcon';
import { ABCIcon } from '../../icons/ABCIcon';
import { BodyShapeIcon } from '../../icons/BodyShapeIcon';
import { WaveHome } from '../../icons/WaveHome';
import { LinesHome } from '../../icons/LinesHome';
import { CrownIcon } from '../../icons/CrownIcon';
import * as S from './styles';
import { StyledContainer, StyledWiderContainer } from '../../../config/styled/shared';
import { Typography } from '../../../config/styled/typography';
import { BadgeMarketing } from '../../icons/BadgeMarketing';
import { HomeYearIcon } from '../../icons/HomeYearIcon';
import { EnvelopeIcon } from '../../icons/EnvelopeIcon';
import { navigate } from 'gatsby';

export const HomeTemplate = () => {
  return (
    <>
      <StyledWiderContainer>
        <S.HeroTop>
          <S.HomeLinesHolder>
            <LinesHome />
          </S.HomeLinesHolder>
          <S.HomeTop>
            <S.HomeTopLeft>
              <S.HeroTopLeft>
                <S.MarketingBadgeHolder>
                  <BadgeMarketing />
                </S.MarketingBadgeHolder>
                <Typography.XXL as="h1" bold responsive css={{ marginBottom: '16px' }}>
                  BERMOND MANAGEMENT
                </Typography.XXL>
                <Typography.S as="h3">
                  <b>Marketing Agency</b>&nbsp;
                  <span>Specializing in Marketing, Advertisement & Branding.</span>
                </Typography.S>
                <S.HeroTopActions>
                  <Button icon={<ArrowIcon />} onClick={() => navigate('/about-us')}>
                    Learn more
                  </Button>
                  <Button icon={<EnvelopeIcon />}></Button>
                </S.HeroTopActions>
              </S.HeroTopLeft>
            </S.HomeTopLeft>
            <S.HomeTopRight>
              <S.HeroImage>
                <StaticImage
                  css={{ zIndex: -2, width: '100%', objectFit: 'contain' }}
                  src="../../../images/crowd.png"
                  alt="Crowd"
                  imgStyle={{ maxWidth: '100%' }}
                />
                <S.BodyShape>
                  <BodyShapeIcon />
                </S.BodyShape>
                <S.Crown>
                  <CrownIcon />
                </S.Crown>
                <S.ABC>
                  <ABCIcon />
                </S.ABC>
                <S.Arrows>
                  <ArrowsIcon />
                </S.Arrows>
              </S.HeroImage>
            </S.HomeTopRight>
          </S.HomeTop>
        </S.HeroTop>
      </StyledWiderContainer>
      <div css={{ backgroundColor: '#000', marginTop: 'auto' }}>
        <WaveHome />
        <StyledContainer>
          <S.Texts>
            <S.HomeYearHolder>
              <HomeYearIcon />
            </S.HomeYearHolder>
            <Typography.L responsive css={{ color: '#ffffff', fontWeight: 400 }}>
              In these time consumers face a great number of different brands every single day. Consequently, if you
              want your brand to become recognizable and unique, Bermond will help you to fulfil that.
            </Typography.L>
            <Typography.L responsive css={{ color: '#ffffff', fontWeight: 400 }}>
              Bermond Management is a marketing agency which helps to realize and manage strategies in order to achieve
              business goals. Whether you are only starting your business or you have already been in these sphere for
              several years, you will be still in need of a proper marketing campaign to make your products visible for
              customers and promote them.{' '}
            </Typography.L>
          </S.Texts>
        </StyledContainer>
      </div>
    </>
  );
};
