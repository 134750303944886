import React from 'react';

export const ArrowsIcon = () => (
  <svg width="159" height="86" viewBox="0 0 159 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 41C39.6856 46.7404 69.3191 46.9222 138.5 41M132 28.5C138.808 34.4556 143.068 37.5418 152.5 42C137.632 46.5944 132.474 51.1113 123 59M8 15.0001C33.5949 18.1041 54.6683 18.2255 106 15.0001M94 2C100.093 7 107.495 10.0519 116 16.2787C105.416 20.7978 102.744 20.7406 96 28.5M1 71.0001C26.5466 68.2331 47.7202 68.6724 99 71.0001M88 58C92.8466 63.858 102.286 67.8936 109 72.2787C98.4159 76.7978 97.7442 76.2406 91 84"
      stroke="#50A6DB"
      strokeWidth="4"
    />
  </svg>
);
