import React from 'react';
import { Layout } from '../components/layout';
import { HomeTemplate } from '../components/templates/home';

const Home = () => {
  return (
    <Layout>
      <HomeTemplate />
    </Layout>
  );
};

export default Home;
